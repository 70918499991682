import React from 'react';
import Features from './Features';
import Pricing from './pricing';
import CommonQuestions from './common-questions';
import { useTranslation } from "react-i18next";
import Testimonials from './Testimonials';
import { useNavigate } from 'react-router-dom';
import FeaturesDetails from './features-details';
import InterfaceHome from './interface-home';
import HowItWorks from './how-it-works';

const Home: React.FunctionComponent<{}> = (props) => {

      const { t, i18n } = useTranslation();
      const navigate = useNavigate();

      const handlClick = () => {
            navigate('/Dashboard')
      }

      return (
            <>
                  <InterfaceHome />

                  <Features />

                  <FeaturesDetails />

                  <HowItWorks />

                  <Pricing />

                  <Testimonials />

                  <CommonQuestions />

                  <div className="section-end dir-rtl">
                        <div className='container '>
                              <h2 className="text-center">{t("Ready to Transform")}</h2>
                              <p className="text-center mt-4">{t("Experience the future")}</p>
                              <div className="btn-holder mt-4 text-center">
                                    <button className="button-login height-button button-login-white marg-10 mb-3" onClick={handlClick}>{t("Get Started for Free")}</button>
                                    <button className="button-signup height-button marg-10 mb-3 visb" onClick={() => navigate('/pricing')}>{t("View Pricing")}</button>
                              </div>
                        </div>
                  </div>

            </>
      )
}
export default Home;
